<!--
 * @Author: hwu
 * @Date: 2021-01-29 11:05:05
 * @Description: loading提示，用于整个页面的加载完成前的提示
 * @LastEditTime: 2021-01-31 22:13:37
-->
<template>
  <div class="loading-wrapper" v-if="show">
    <div class="loading-box">
      <div class="loading-item"></div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'loading-box',
  props: {
    show: { type: Boolean, required: true, default: false }
  }
}
</script>
<style lang="scss" scoped>
.loading-wrapper {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100vh;
  background-color: $color-background;
  z-index: 4000;
  .loading-box {
    position: absolute;
    top: 55%;
    left: 0;
    width: 100%;
    margin-top: -300px;
    text-align: center;
  }
}
.loading-item {
  position: relative;
  display: inline-block;
  width: 100px;
  height: 100px;
  margin: 120px;
  vertical-align: middle;
  border: 12px double rgba(#C4D4E7, 0.7);
  border-radius: 50%;
  -webkit-animation: ball-turn 2s linear infinite;
  animation: ball-turn 2s linear infinite;
  &:before,
  &:after {
    content: '';
    position: absolute;
    width: 20px;
    height: 20px;
    background: rgba(#C4D4E7, 0.9);
    border-radius: 50%;
    bottom: 0;
    right: 62px;
  }

  &:after {
    left: 62px;
    top: 0;
  }
}

@-webkit-keyframes ball-turn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}

@keyframes ball-turn {
  0% {
    -webkit-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
</style>
